import { Button, Dialog } from '@material-ui/core'
import styled from 'styled-components'

export const CustomDialogTitle = styled.h3`
  font-family: 'Noto Sans HK', sans-serif;
  font-size: 20px;
  color: rgb(81, 88, 97);
  font-weight: 700;
  margin: 0;
  margin-bottom: 16px;
  line-height: normal;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
`
export const CustomDialogContent = styled.p`
  font-family: 'Noto Sans HK', sans-serif;
  font-size: 14px;
  line-height: normal;
  padding: 0;
  color: rgb(57, 57, 70);
  font-weight: 500;
  margin: 0;
  margin-bottom: 26px;
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }
`

export const CustomStyledDialog = styled(Dialog)`
  &.customStyledDialog {
    [role='dialog'] {
      padding: 20px;
      border-radius: 23px;
    }
  }
`
export const CustomStyledButton = styled(Button)`
  border-radius: 7px;
  font-size: 14px;
  font-family: 'Noto Sans HK', sans-serif;
  padding: 10px 25px;
  color: white;
  text-transform: none;
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 10px 10px;
  }
  &.skip-btn {
    background-color: rgb(13, 135, 98);
  }
  &.cancel-btn {
    background-color: rgb(241, 241, 241);
    color: rgb(57, 57, 70);
  }
`
export const CustomDialogActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
`
