import React, { useEffect } from 'react'
import Modal from '@material-ui/core/Modal'
import Uppy from '@uppy/core'
import { SrOnly } from 'Shared/Theme/utilities.styles'

import {
  StyledCloseButton,
  StyledCloseIcon,
  StyledDescriptionParagraph,
  StyledHeadingContainer,
  StyledHeadingDialog,
  StyledModalBody,
  StyledModalHeading,
} from '../style'

import FileUploadModal from './FileUploadModal'
import TypedDatePicker from './TypedDatePicker'

export type ModalTemplateProps = {
  children: React.ReactNode
  open: boolean
  hasCalendar?: boolean
  handleDateChange?: React.ChangeEventHandler
  dateError?: boolean
  datePickerDate?: string
  heading: string
  description?: string
  handleCancel?: () => void
  uppy?: Uppy.Uppy
  hasXToClose?: boolean
  width?: string
  tabletwidth?: string
  smallerHeight?: boolean
  smallerRadius?: boolean
  addCase?: boolean
  textAlign?: string
  headingMarginBottom?: string
  subHeader?: string
  modalPadding?: string
}

const ModalTemplate = ({
  children,
  open,
  hasCalendar,
  handleDateChange,
  datePickerDate,
  dateError,
  heading,
  description,
  handleCancel,
  uppy,
  hasXToClose,
  width,
  tabletwidth,
  smallerHeight,
  smallerRadius,
  addCase,
  textAlign,
  headingMarginBottom,
  modalPadding,
  subHeader,
}: ModalTemplateProps) => {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('closeModalBtn')?.focus()
      const sentinelStart = document.querySelector(
        'div[role="dialog"] div[data-test="sentinelStart"]'
      )
      const sentinelEnd = document.querySelector(
        'div[role="dialog"] div[data-test="sentinelEnd"]'
      )
      sentinelStart?.setAttribute(
        'aria-label',
        'Add Client Dialog content start'
      )
      sentinelEnd?.setAttribute('aria-label', 'Add Client Dialog content end')
    }, 100)
  }, [])
  return (
    <>
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick' && handleCancel) {
            return handleCancel()
          } else {
            return null
          }
        }}
        aria-labelledby={heading}
        aria-describedby={description ? description : heading}
        role='dialog'
        aria-modal='true'
      >
        <StyledModalBody
          width={width ? width : '61%'}
          tabletwidth={tabletwidth}
          smallerHeight={smallerHeight}
          smallerRadius={smallerRadius}
          addCase={addCase}
          modalPadding={modalPadding}
        >
          <StyledModalHeading
            addCase={addCase}
            headingMarginBottom={headingMarginBottom}
          >
            {hasXToClose && (
              <StyledCloseButton
                aria-label='Close'
                data-testid='close-modal-button'
                onClick={handleCancel}
                id='closeModalBtn'
              >
                <SrOnly>Close</SrOnly>
                <StyledCloseIcon />
              </StyledCloseButton>
            )}
            <StyledHeadingContainer
              alignItems='center'
              justCont='space-between'
            >
              <StyledHeadingDialog $textAlign={textAlign}>
                {heading}
              </StyledHeadingDialog>
              {hasCalendar && (
                <TypedDatePicker
                  date={datePickerDate}
                  onChange={handleDateChange}
                  error={dateError}
                />
              )}
            </StyledHeadingContainer>

            {description && (
              <StyledHeadingContainer
                fontSize='0.75rem'
                fontWeight={400}
                justCont='space-between'
                alignItems='center'
              >
                {description && (
                  <StyledDescriptionParagraph marginBottom='0.625rem'>
                    {description}
                  </StyledDescriptionParagraph>
                )}
              </StyledHeadingContainer>
            )}
            {subHeader && (
              <StyledHeadingContainer padding='0 0 20px 0'>
                {subHeader}
              </StyledHeadingContainer>
            )}
          </StyledModalHeading>

          {children}
        </StyledModalBody>
      </Modal>
      {uppy && <FileUploadModal uppy={uppy} />}
    </>
  )
}

export default ModalTemplate
