import React from 'react'

export const directionsIconPath = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      fill='#11ab7c'
      d='M22.43 10.59l-9.01-9.01c-.75-.75-2.07-.76-2.83 0l-9 9c-.78.78-.78 2.04 0 2.82l9 9c.39.39.9.58 1.41.58.51 0 1.02-.19 1.41-.58l8.99-8.99c.79-.76.8-2.02.03-2.82zm-10.42 10.4l-9-9 9-9 9 9-9 9zM8 11v4h2v-3h4v2.5l3.5-3.5L14 7.5V10H9c-.55 0-1 .45-1 1z'
    />
  </>
)

export const infoIconPath = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      fill='#11ab7c'
      d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'
    />
  </>
)

export const exploreIconPath = (
  <path
    style={{ fill: '#11ab7c' }}
    d='M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm2.19 12.19L6 18l3.81-8.19L18 6l-3.81 8.19z'
  />
)

export const languagesIconPath = (
  <>
    <path className='a' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />
    <path
      className='b'
      style={{ fill: '#11ab7c' }}
      d='M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2Zm6.93,6H15.97a15.649,15.649,0,0,0-1.38-3.56A8.03,8.03,0,0,1,18.92,8ZM12,4.04A14.087,14.087,0,0,1,13.91,8H10.09A14.087,14.087,0,0,1,12,4.04ZM4.26,14a7.822,7.822,0,0,1,0-4H7.64a16.515,16.515,0,0,0-.14,2,16.515,16.515,0,0,0,.14,2Zm.82,2H8.03a15.649,15.649,0,0,0,1.38,3.56A7.987,7.987,0,0,1,5.08,16ZM8.03,8H5.08A7.987,7.987,0,0,1,9.41,4.44,15.649,15.649,0,0,0,8.03,8ZM12,19.96A14.087,14.087,0,0,1,10.09,16h3.82A14.087,14.087,0,0,1,12,19.96ZM14.34,14H9.66a14.713,14.713,0,0,1-.16-2,14.585,14.585,0,0,1,.16-2h4.68a14.585,14.585,0,0,1,.16,2A14.713,14.713,0,0,1,14.34,14Zm.25,5.56A15.649,15.649,0,0,0,15.97,16h2.95A8.03,8.03,0,0,1,14.59,19.56ZM16.36,14a16.515,16.515,0,0,0,.14-2,16.515,16.515,0,0,0-.14-2h3.38a7.822,7.822,0,0,1,0,4Z'
    />
  </>
)

export const outlineLanguagesIconPath = (
  <>
    <path />
    <path className='a' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />s
    <path
      className='b'
      style={{ fill: '#11ab7c' }}
      d='M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2Zm6.93,6H15.97a15.649,15.649,0,0,0-1.38-3.56A8.03,8.03,0,0,1,18.92,8ZM12,4.04A14.087,14.087,0,0,1,13.91,8H10.09A14.087,14.087,0,0,1,12,4.04ZM4.26,14a7.822,7.822,0,0,1,0-4H7.64a16.515,16.515,0,0,0-.14,2,16.515,16.515,0,0,0,.14,2Zm.82,2H8.03a15.649,15.649,0,0,0,1.38,3.56A7.987,7.987,0,0,1,5.08,16ZM8.03,8H5.08A7.987,7.987,0,0,1,9.41,4.44,15.649,15.649,0,0,0,8.03,8ZM12,19.96A14.087,14.087,0,0,1,10.09,16h3.82A14.087,14.087,0,0,1,12,19.96ZM14.34,14H9.66a14.713,14.713,0,0,1-.16-2,14.585,14.585,0,0,1,.16-2h4.68a14.585,14.585,0,0,1,.16,2A14.713,14.713,0,0,1,14.34,14Zm.25,5.56A15.649,15.649,0,0,0,15.97,16h2.95A8.03,8.03,0,0,1,14.59,19.56ZM16.36,14a16.515,16.515,0,0,0,.14-2,16.515,16.515,0,0,0-.14-2h3.38a7.822,7.822,0,0,1,0,4Z'
    />
  </>
)

export const locationIconPath = (
  <>
    <path className='a' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />
    <path
      className='b'
      style={{ fill: '#11ab7c' }}
      d='M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2ZM7,9A5,5,0,0,1,17,9c0,2.88-2.88,7.19-5,9.88C9.92,16.21,7,11.85,7,9Z'
    />
    <path></path>
    <circle
      className='b'
      style={{ fill: '#11ab7c' }}
      cx='2.5'
      cy='2.5'
      r='2.5'
      transform='translate(9.5 6.5)'
    />
  </>
)

export const outlineLocationIconPath = (
  <>
    <path className='a' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />
    <path
      className='b'
      style={{ fill: '#11ab7c' }}
      d='M12,2A7,7,0,0,0,5,9c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,12,2ZM7,9A5,5,0,0,1,17,9c0,2.88-2.88,7.19-5,9.88C9.92,16.21,7,11.85,7,9Z'
    />
    <circle
      className='b'
      style={{ fill: '#11ab7c' }}
      cx='2.5'
      cy='2.5'
      r='2.5'
      transform='translate(9.5 6.5)'
    />
  </>
)

export const mailIconPath = (
  <path
    style={{ fill: '#11ab7c' }}
    d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z'
  />
)

export const outlineMailIconPath = (
  <path
    style={{ fill: '#11ab7c' }}
    d='M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z'
  />
)

export const phoneIconPath = (
  <>
    <path className='a' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />
    <path
      className='b'
      style={{ fill: '#11ab7c' }}
      d='M6.54,5a12.312,12.312,0,0,0,.45,2.59l-1.2,1.2A14.826,14.826,0,0,1,5.03,5H6.54M16.4,17.02a12.753,12.753,0,0,0,2.6.45v1.49a15.426,15.426,0,0,1-3.8-.75l1.2-1.19M7.5,3H4A1,1,0,0,0,3,4,17,17,0,0,0,20,21a1,1,0,0,0,1-1V16.51a1,1,0,0,0-1-1,11.407,11.407,0,0,1-3.57-.57.839.839,0,0,0-.31-.05,1.024,1.024,0,0,0-.71.29l-2.2,2.2a15.149,15.149,0,0,1-6.59-6.59l2.2-2.2a1,1,0,0,0,.25-1.02A11.36,11.36,0,0,1,8.5,4,1,1,0,0,0,7.5,3Z'
    />
  </>
)

export const outlinePhoneIconPath = (
  <>
    <path fill='none' d='M0,0H24V24H0Z' />
    <path></path>
    <path
      fill='#11ab7c'
      d='M6.54,5a12.312,12.312,0,0,0,.45,2.59l-1.2,1.2A14.826,14.826,0,0,1,5.03,5H6.54M16.4,17.02a12.753,12.753,0,0,0,2.6.45v1.49a15.426,15.426,0,0,1-3.8-.75l1.2-1.19M7.5,3H4A1,1,0,0,0,3,4,17,17,0,0,0,20,21a1,1,0,0,0,1-1V16.51a1,1,0,0,0-1-1,11.407,11.407,0,0,1-3.57-.57.839.839,0,0,0-.31-.05,1.024,1.024,0,0,0-.71.29l-2.2,2.2a15.149,15.149,0,0,1-6.59-6.59l2.2-2.2a1,1,0,0,0,.25-1.02A11.36,11.36,0,0,1,8.5,4,1,1,0,0,0,7.5,3Z'
    />
  </>
)

export const publicIconPath = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      fill='#11ab7c'
      d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-.61.08-1.21.21-1.78L8.99 15v1c0 1.1.9 2 2 2v1.93C7.06 19.43 4 16.07 4 12zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41C17.92 5.77 20 8.65 20 12c0 2.08-.81 3.98-2.11 5.4z'
    />
  </>
)

export const publicBlackIconPath = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path
      fill='#000'
      d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-.61.08-1.21.21-1.78L8.99 15v1c0 1.1.9 2 2 2v1.93C7.06 19.43 4 16.07 4 12zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41C17.92 5.77 20 8.65 20 12c0 2.08-.81 3.98-2.11 5.4z'
    />
  </>
)

export const shareIconPath = (
  <path
    style={{ fill: '#11ab7c' }}
    d='M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z'
    transform='scale(-1,1) translate(-24,0)'
  />
)

export const timeIconPath = (
  <>
    <path className='a' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />
    <path
      className='b'
      style={{ fill: '#11ab7c' }}
      d='M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.5-13H11v6l5.25,3.15L17,14.92l-4.5-2.67Z'
    />
    <path />
    <path />
  </>
)

export const expandedIconPath = (
  <path
    style={{ fill: '#11ab7c' }}
    d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'
  />
)

export const collapsedIconPath = (
  <path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z' />
)

export const arrowLeftIconPath = (
  <>
    <path className='a' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />
    <path d='M20,11H7.83l5.59-5.59L12,4,4,12l8,8,1.41-1.41L7.83,13H20Z' />
  </>
)

export const preferredStar = (
  <>
    <path className='a' style={{ fill: 'none' }} d='M0,0H22.527V22.527H0Z' />
    <path
      style={{ fill: '#ffcd2a' }}
      d='M11.386,16.333l5.8,3.5-1.539-6.6L20.772,8.8l-6.749-.573L11.386,2,8.749,8.223,2,8.8l5.125,4.44-1.539,6.6Z'
      transform='translate(-0.123 -0.123)'
    />
  </>
)
export const deleteIcon = (
  <>
    <path
      d='M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5'
      stroke='#E31010'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>
)

export const arrowDownBlackIconPath = (
  <path
    d='M1 1L7 7L13 1'
    stroke='#151515'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
)

export const arrowIconPath = (
  <path
    d='M1 7L7 1L13 7'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
)
