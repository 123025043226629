/* eslint-disable @typescript-eslint/await-thenable */
import React from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import authClient from 'ApolloClient/authClient'
import { ME } from 'Components/Auth/Hooks/gql'
import ConfirmOrCancel from 'Components/CaseManagement/Modals/Shared/ConfirmOrCancel'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { change, submit } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'

import { CASE_MANAGER_SKIP_CLIENT_OTP } from './gql'
import { StyledHeadingDialog, StyledParagraph } from './style'

const ConfirmSkipVerificationModal = ({ resetContext, open }) => {
  const { data } = useQuery(ME)
  let userEmail = data?.me?.email
  let userId = data?.me?.id
  const dispatch = useDispatch()

  const [caseManagerSkipClientOtp] = useMutation(CASE_MANAGER_SKIP_CLIENT_OTP, {
    client: authClient,
  })

  const handleSkipVerification = (e) => {
    e.preventDefault()
    caseManagerSkipClientOtp({
      variables: {
        email: userEmail || '',
        id: userId || '',
        skip: true,
      },
    })
      .then(() => {
        console.log('Success')
      })
      .catch((error) => {
        console.error('Error skipping verification', error)
      })
  }
  return (
    <ModalTemplate open={open} textAlign={'center'}>
      <StyledHeadingDialog>
        Are you sure you want to skip verification?
      </StyledHeadingDialog>
      <StyledParagraph textAlign='center' marginBottom='.5em'>
        Please note that by selecting “Yes,” the client will become unverified.
        The client will not be able to access their profile without the
        assistance of a case manager.
      </StyledParagraph>
      <ConfirmOrCancel
        handleOnCancel={resetContext}
        handleOnConfirm={async (e) => {
          await dispatch(
            change(PRE_SCREENER_FORM_NAME, 'verification_skipped', true)
          )
          handleSkipVerification(e)
          dispatch(submit(PRE_SCREENER_FORM_NAME))
          resetContext()
        }}
        confirmLabel='Yes, Skip Verification'
        cancelLabel='Cancel'
      />
    </ModalTemplate>
  )
}

export default ConfirmSkipVerificationModal
