import { StyledButton } from 'Components/CaseManagement/Reporting/Grids/Analytics/Activities/EditActivityCell/style'
import styled from 'styled-components'

export const StyledSkipVerificationButton = styled(StyledButton)`
  color: #0fae80;
  border: 1px solid #0fae80;
  border-radius: 0.3rem;
  height: 3.8rem;
  padding: 0 0.3rem;
  font-weight: 500;
  font-size: 1.125rem;
  width: 100%;
  text-align: center;
  position: relative;
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`
StyledSkipVerificationButton.displayName = 'StyledSkipVerificationButton'
