export const Warningsvg = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill='#FDE6E5' />
      <path
        d='M19.9998 17.0002V21.0002M19.9998 25.0002H20.0098M18.6151 11.892L10.3902 26.0986C9.93398 26.8866 9.70588 27.2806 9.73959 27.6039C9.769 27.886 9.91677 28.1423 10.1461 28.309C10.4091 28.5002 10.8643 28.5002 11.7749 28.5002H28.2246C29.1352 28.5002 29.5904 28.5002 29.8534 28.309C30.0827 28.1423 30.2305 27.886 30.2599 27.6039C30.2936 27.2806 30.0655 26.8866 29.6093 26.0986L21.3844 11.892C20.9299 11.1068 20.7026 10.7142 20.4061 10.5824C20.1474 10.4673 19.8521 10.4673 19.5935 10.5824C19.2969 10.7142 19.0696 11.1068 18.6151 11.892Z'
        stroke='#E31010'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
