/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ME } from 'Components/Auth/Hooks/gql'
import ConfirmationModal from 'Components/CaseManagement/Modals/ConfirmationModal/ConfirmationModal'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { StyledBorderBottom } from 'Components/LocalResources/LocationCard/style'
import { DialogTitle } from 'Components/Shared/Dialog/Dialog'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'
import { isJestTest } from 'Shared/helpers'

import { CustomStyledHeader } from '../../Header/style'
import Surface from '../../Surface/Surface'
import SendIcon from '../button_referral.svg'
import { CREATE_REFERRAL } from '../StartReferral/hooks/gql'
import StartReferralModal from '../StartReferral/StartReferral'
import { ContainerButton, StyledButtonReferral } from '../style'

import ActionsToolbar from './ActionsToolbar'

const Header = (props: {
  badges: any
  location: any
  preferredPartner: any
  parentCategoryIdFromContext: any
  setReferralData: any
  clientId: any
  activateFavorites: boolean
  createFindHelpLocationInput: {
    officeId: string
    providerId: string
    programId: string
  }
}) => {
  const { badges, location, activateFavorites, createFindHelpLocationInput } =
    props

  const clientIdFromSession = sessionStorage.getItem('clientId')

  const [createReferral] = useMutation(CREATE_REFERRAL)

  const inCaseManagementClientPage = window.location.pathname.includes(
    'case-management/clients'
  )
  const { data: userData } = useQuery(ME)

  const isFeatureToggleOn = useIsFeatureToggleOn()

  const { numbers, url, coordinates, description, program_name } = location
  //ToDo: FIND HELP POC - phone number structure is different in find help
  const phoneNumber = numbers?.primary ? numbers?.primary : null
  //ToDo: FIND HELP POC - do we not get coordinates back?
  const { lat, lng } = coordinates || {}

  const enableReferrals = isFeatureToggleOn('referrals')

  const [open, setOpen] = useState(false)
  const [openReferral, setOpenReferral] = useState(false)
  const [referralInfo, setReferralInfo] = useState(null)
  const [confirmReferral, setConfirmReferral] = useState(false)
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [successReferral, setSuccessReferral] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const submitReferral = (values: any) => {
    if (!inCaseManagementClientPage) {
      setReferralInfo({ ...values })
    } else {
      props.setReferralData({ ...values })
    }
    setOpenReferral(false)
    setConfirmReferral(true)
    setError(false)
  }

  const handleOpenReferral = () => {
    setOpenReferral(true)
  }

  const handleCloseReferral = () => {
    setOpenReferral(false)
  }

  const doCreateReferral = async () => {
    setError(false)
    setSubmitting(true)
    try {
      const { data } = await createReferral({
        variables: {
          clientId: clientIdFromSession || '',
          inputs: {
            referralType: 'EXTERNAL',
            recipientType: 'CLIENT',
            referralCategories: referralInfo?.services || [],
            locationId: referralInfo.locationId,
          },
        },
      })
      if (data) {
        setConfirmReferral(false)
        setSuccessReferral(true)
      }
    } catch (error) {
      !isJestTest() && console.error(error)
      setError(true)
    }
    setSubmitting(false)
  }

  const actionType = 'HEADER'

  return (
    <>
      <Surface
        background='transparent'
        elevation={5}
        boxShadow={'none'}
        width={'100%'}
      >
        <CustomStyledHeader padding={'1rem 1rem 0'} actionType={actionType}>
          <StyledBorderBottom style={{ paddingBottom: '0' }}>
            <ActionsToolbar
              phoneNumber={phoneNumber}
              lat={lat}
              lng={lng}
              url={url}
              activateFavorites={activateFavorites}
              createFindHelpLocationInput={createFindHelpLocationInput}
            />
          </StyledBorderBottom>

          {(clientIdFromSession || props.clientId) && enableReferrals && (
            <ContainerButton style={{ padding: '1rem' }}>
              <StyledButtonReferral
                color='primary'
                fullWidth
                onClick={handleOpenReferral}
                data-testid='start-referral'
              >
                <img src={SendIcon} width={20} alt='Send icon' />{' '}
                {`Start referral for this location`}
              </StyledButtonReferral>
            </ContainerButton>
          )}
          {/* <LocationServiceOfferings location={location} /> */}
        </CustomStyledHeader>
      </Surface>

      {/* Modals */}
      <InfoModal
        name={program_name}
        handleClose={handleClose}
        open={open}
        description={description}
      />
      <StartReferralModal
        open={openReferral}
        handleClose={handleCloseReferral}
        submitReferral={submitReferral}
        serviceBadges={badges}
        location={location}
        userData={userData?.me}
        parentCategoryIdFromContext={props.parentCategoryIdFromContext}
      />
      {confirmReferral && (
        <ConfirmationModal
          handleOnCancel={() => {
            setConfirmReferral(false)
          }}
          heading={'Are you sure you want to start a referral?'}
          handleOnConfirm={
            !inCaseManagementClientPage
              ? doCreateReferral
              : () => setConfirmReferral(false)
          }
          confirmLabel={
            submitting ? 'Starting referral' : 'Yes, Start referral'
          }
          error={error}
          submitting={submitting}
        />
      )}
      <ModalTemplate
        handleCancel={() => {
          setSuccessReferral(false)
        }}
        heading='Referral Successfully Started!'
        open={successReferral}
        hasXToClose={true}
      >
        {userData?.me?.isACaseManager ? (
          <>
            <p>
              We've started a referral for {location.name}. This will appear in
              this client's record.The client will also recieve a message with
              details about the location.
            </p>
          </>
        ) : (
          <p>
            We've started a referral for {location.name}. This will appear in
            your client record, and you will receive a message with details
            about the location.
          </p>
        )}
      </ModalTemplate>
    </>
  )
}

export default Header

const StyledDialog = withStyles({
  root: {
    '& .MuiPaper-rounded': {
      width: '100%',
      textAlight: 'left',
      margin: '0',
      '@media (max-width: 1023px)': {
        padding: '1rem',
        borderRadius: '0.75rem',
      },
      '@media (min-width: 1024px)': {
        padding: '2rem',
        borderRadius: '1rem',
      },
      '& #customized-dialog-title': {
        padding: '0px',
      },
    },
    '& #closeModalBtn': {
      width: '1.5rem',
      height: '1.5rem',
      '@media (max-width: 1023px)': {
        top: '2.5rem',
        right: '2rem',
      },
      '@media (min-width: 1024px)': {
        top: '2.5rem',
        right: '2rem',
      },
    },
    '& #closeModalBtn svg': {
      width: '1.5rem',
      height: '1.5rem',
    },
    '& .program-header': {
      fontSize: '1.75rem',
      fontWeight: 700,
      fontFamily: 'inherit',
      margin: '0',
    },
    '& .program-name': {
      fontSize: '1.35rem',
      textDecoration: 'underline dotted green',
      fontWeight: 500,
      color: '#29283E',
      fontFamily: 'inherit',
      margin: '0.5rem 0',
    },
  },
})(Dialog)
const InfoModal = ({
  handleClose,
  open,
  name,
  description,
}: {
  handleClose: () => void
  open: boolean
  name: string
  description: string
}) => {
  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        <h2 className='program-header'>Program Details</h2>
        <h3 className='program-name'>{name}</h3>
      </DialogTitle>
      <MuiDialogContent
        style={{
          padding: '0',
          marginTop: '1rem',
        }}
      >
        <Typography
          style={{
            fontSize: '1.25rem',
            fontWeight: '400',
            color: '#393946',
          }}
        >
          {description}
        </Typography>
      </MuiDialogContent>
    </StyledDialog>
  )
}
