import { gql } from '__generated__/gql'

export const CASE_MANAGER_SKIP_CLIENT_OTP = gql(`
mutation CaseManagerSkipClientOtp($email: String, $skip: Boolean) {
  caseManagerSkipClientOtp(email: $email, skip: $skip) {
    client {
      email
      id
    }
  }
}
`)
