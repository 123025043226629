import React from 'react'

import { Loadingwrapper } from './style'

const Loading = () => {
  return (
    <Loadingwrapper>
      <span className='loader'></span>
    </Loadingwrapper>
  )
}

export default Loading
