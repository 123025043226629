import { updateResourcesZipCode, updateZipCode } from 'Actions/geolocation'
import {
  loadingFailed,
  loadingStarted,
  loadingStopped,
  updateShowLocationList,
} from 'Reducers/uiSlice'
import { axiosWithoutAuthHeaders } from 'Shared/axiosInstances'
import { API_BASE } from 'Shared/constants'
import { getZipCodeFromCoordinates, isJestTest } from 'Shared/helpers'

export const UPDATE_FILTERED_SERVICE_TAGS = 'UPDATE_FILTERED_SERVICE_TAGS'
export const UPDATE_FILTER_LEVEL = 'UPDATE_FILTER_LEVEL'
export const LOAD_INITIAL_FILTERS = 'LOAD_INITIAL_FILTERS'
export const UPDATE_QUICK_FILTER_SELECTED = 'UPDATE_QUICK_FILTER_SELECTED'
export const UPDATE_DISTANCE_FILTER_UI = 'UPDATE_DISTANCE_FILTER_UI'
export const UPDATE_DISTANCE_AROUND = 'UPDATE_DISTANCE_AROUND'
export const TOGGLE_SINGLE_STOP_FILTER = 'TOGGLE_SINGLE_STOP_FILTER'
export const UPDATE_FILTERED_LOCATIONS = 'UPDATE_FILTERED_LOCATIONS'
export const UPDATE_LANGUAGE_SELECTED = 'UPDATE_LANGUAGE_SELECTED'
export const UPDATE_QUERY = 'UPDATE_QUERY'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const UPDATE_SHOW_ZERO_RESULTS = 'UPDATE_SHOW_ZERO_RESULTS'
export const UPDATE_CATAGORIES_FILTER = 'UPDATE_CATAGORIES_FILTER'

/**
 * Updates the filtered service tags.
 *
 * @param {any} payload - The payload containing the updated service tags.
 * @return {Object} - The action object with the type and payload.
 */
export const updateFilteredServiceTags = (payload) => {
  return {
    type: UPDATE_FILTERED_SERVICE_TAGS,
    payload,
  }
}

export const updateCategoriesFilter = (payload) => {
  return {
    type: 'UPDATE_CATAGORIES_FILTER',
    payload,
  }
}

export const updateFilterLevel = (payload) => {
  return {
    type: UPDATE_FILTER_LEVEL,
    payload,
  }
}

/**
 *
 * @param {Object} payload
 * @returns {{payload: {Object}, type: string}}
 */
export const loadInitialFilters = (payload) => ({
  type: LOAD_INITIAL_FILTERS,
  payload,
})

/**
 *
 * @param {Number} payload
 * @returns {{payload: Number, type: string}}
 */
export const updateQuickFilterSelected = (payload) => ({
  type: UPDATE_QUICK_FILTER_SELECTED,
  payload,
})

export const updateFilteredLocations = (filteredLocations) => ({
  type: UPDATE_FILTERED_LOCATIONS,
  filteredLocations,
})

export const updateShowZeroResults = (updateShowZeroResults) => ({
  type: UPDATE_SHOW_ZERO_RESULTS,
  payload: updateShowZeroResults,
})

export const updateDistanceAround = (distance) => ({
  type: UPDATE_DISTANCE_AROUND,
  distance,
})

/**
 *
 * @param {Object} payload
 * @returns {{payload: {Object}, type: string}}
 */
export const updateDistanceUI = (payload) => ({
  type: UPDATE_DISTANCE_FILTER_UI,
  payload,
})

/**
 *
 * @returns {{type: string}}
 */
export const toggleSingleStopFilter = () => {
  return { type: TOGGLE_SINGLE_STOP_FILTER }
}

export const updateLanguageSelected = (payload) => ({
  type: UPDATE_LANGUAGE_SELECTED,
  payload,
})

export const updateQuery = (query) => {
  return {
    type: UPDATE_QUERY,
    query,
  }
}

export const catchAxios = (thrown) => {
  if (axiosWithoutAuthHeaders.isCancel(thrown)) {
    console.log('Request canceled', thrown.message)
  } else {
    console.log(thrown)
  }
}

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})
/**
 *
 * @returns {Function | null}
 */
export const fetchFilters = () => (dispatch, getState) => {
  const { filters } = getState()
  if (filters.initialState) {
    return axiosWithoutAuthHeaders
      .get(`${API_BASE}/filters`)
      .then((result) => {
        const { data } = result
        return dispatch(loadInitialFilters(data))
      })
      .catch((thrown) => {
        if (!isJestTest()) catchAxios(thrown)
      })
  } else {
    return null
  }
}

/**
 *
 * @param {Object} filters
 * @param {String} name
 * @returns {string[]}
 */
export const getActiveFilters = (filters, name) => {
  return Object.keys(filters)
    .map((id) => ({ ...filters[id], id: id }))
    .filter((obj) => obj.selected === true)
    .map((obj) => `${name}:${obj.id}`)
}

export const processSearchResults = (results) => {
  const locationsObj = results.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.id]: currentValue,
    }),
    {}
  )
  return locationsObj
}

/**
 *
 * @param {String} zipCode
 * @returns {Function}
 */
export const parseQuery =
  (query, resourcesMap = false) =>
  (dispatch) => {
    const zipCodeRegex = /(\d{5})/g
    const zipCodeMatch = query?.match(zipCodeRegex)
    if (resourcesMap && zipCodeMatch) {
      const zipCode = zipCodeMatch[0]
      const searchTerm = query.replace(zipCode, '')
      dispatch(updateResourcesZipCode(zipCode))
      dispatch(updateQuery(searchTerm))
      dispatch(updateShowLocationList(true))
    } else if (zipCodeMatch) {
      const zipCode = zipCodeMatch[0]
      const searchTerm = query.replace(zipCode, '')
      dispatch(updateZipCode(zipCode))
      dispatch(updateQuery(searchTerm))
    } else {
      dispatch(updateQuery(query))
    }
  }

export const getSelectedFilterTags = (tagName, filters) =>
  filters
    .filter(
      (quickFilter) =>
        quickFilter.selected === true && quickFilter.filterKey === tagName
    )
    .map((quickFilter) => quickFilter.filterValue)
    .join(',')

export const getFindHelpURL = (filterState, zipCode) => {
  const {
    query,
    distance: { meters },
    quickFilters,
    serviceTags,
  } = filterState

  const miles = Math.round(meters * 0.000621371)

  const isZipCode = query?.match(/(\d{5})/g)

  const serviceTagValue = serviceTags

  const attributeTagValue = getSelectedFilterTags(
    'attributeTag',
    Object.values(quickFilters)
  )

  let url = `${API_BASE}/community_resources/programs?zip_code=${zipCode}&distance=${miles}`

  if (serviceTagValue) {
    url += `&serviceTag=${serviceTagValue}`
  }

  if (attributeTagValue) {
    url += `&attributeTag=${attributeTagValue}`
  }

  //ToDo: FIND HELP POC - figure out why we need this. Doesn't parse query do this? Race condition?
  if (query && !isZipCode) {
    url += `&terms=${query}`
  }

  return url
}
export const searchFindHelpAPI = (zipCode) => (dispatch, getState) => {
  if (!zipCode) {
    console.error('searchFindHelpAPI(): NO ZIP')
    return
  }
  dispatch(updateShowZeroResults(false))
  dispatch(loadingStarted())

  const url = getFindHelpURL(getState().filters, zipCode)

  return axiosWithoutAuthHeaders
    .get(url)
    .then(({ data }) => {
      dispatch(loadingStopped())
      const resultsAsObject = data.reduce(
        (locationsObject, location) => ({
          ...locationsObject,
          [location.id]: location,
        }),
        {}
      )
      if (!data.length) {
        dispatch(updateShowZeroResults(true))
      }
      dispatch(updateFilteredLocations(resultsAsObject))
    })
    .catch((error) => {
      dispatch(loadingFailed())
      console.error(error)
    })
}

export const filterLocations = (searchThisArea) => (dispatch, getState) => {
  const {
    resourcesZipCode,
    coordinates: { lat, lng },
  } = getState().geolocation
  if (resourcesZipCode && searchThisArea) {
    dispatch(searchFindHelpAPI(resourcesZipCode))
  } else if (lat && lng) {
    getZipCodeFromCoordinates({ lat, lng })
      .then((zipCode) => {
        dispatch(updateResourcesZipCode(zipCode))
        dispatch(searchFindHelpAPI(zipCode))
      })
      .catch((err) => {
        console.error(`Google maps API error: ${err}`)
      })
  }
}
