import React from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

interface PaginationResourcesProps {
  page: number
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void
  count?: number // Optional count of total pages, to be determined dynamically later
}
const Paginationresources: React.FC<PaginationResourcesProps> = ({
  page,
  onChange,
  count,
}) => {
  return (
    <Stack
      spacing={2}
      direction='row'
      justifyContent='center'
      alignItems='center'
    >
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        className='customPaginationStyle'
      />
    </Stack>
  )
}

export default Paginationresources
