import React, { useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from 'Shared/constants'
import closeIconPath from 'Shared/Icons/closeIconPath'
import searchIconPath from 'Shared/Icons/searchIconPath'
import { selectText } from 'Shared/keyboardHelpers'
import { SrOnly } from 'Shared/Theme/utilities.styles'

import Icon from '../Icon/Icon'

import {
  SearchButton as StyledSearchButton,
  StyledInput,
  StyledInputCaseManagement,
} from './style'

export type SearchInputProps = {
  className?: string
  handleOnBlur?: React.FocusEventHandler<HTMLInputElement>
  handleOnFocus?: React.FocusEventHandler<HTMLInputElement>
  handleSearch: (searchTerm: string) => void
  iconFill: string
  id?: string
  initialValue?: string
  isCaseManagement?: boolean
  placeholder: string
  screenReaderLabel?: string
  isReporting?: boolean
  autoSearch?: boolean
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void | boolean
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void | boolean
  pattern?: string
  setError?: (error: boolean) => void
  error?: boolean
  maxCharLimit?: number
}

const SearchInput = ({
  className,
  handleOnBlur,
  handleOnFocus,
  handleSearch,
  id,
  initialValue = '',
  placeholder,
  isCaseManagement,
  iconFill,
  isReporting,
  autoSearch = true,
  onChange,
  pattern,
  onKeyPress,
  setError,
  error,
  maxCharLimit,
}: SearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState(initialValue)

  const StyledInputField = isCaseManagement
    ? StyledInputCaseManagement
    : StyledInput

  const inputElement = useRef<HTMLInputElement>(null)

  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const isTablet = useMediaQuery({ query: TABLET_MEDIA_QUERY })

  let iconScale

  if (isCaseManagement) {
    iconScale = '1.2rem'
  } else if (isMobile || isTablet) {
    iconScale = '1.75rem'
  } else {
    iconScale = '1.5rem'
  }

  const handleClearText = () => {
    if (searchTerm !== '') {
      inputElement?.current?.focus()
      setSearchTerm('')
      handleSearch('')
    }
  }

  const renderButton = () => {
    if (!autoSearch) {
      return (
        <StyledSearchButton
          isCaseManagement={false}
          aria-label={'search'}
          type={'button'}
          role='button'
          disabled={error}
          onClick={() => {
            if (!error) {
              handleSearch(searchTerm)
            } else {
              setError && setError(true)
            }
          }}
        >
          <Icon
            scale={iconScale}
            fill={'none'}
            cursorOnHover={'pointer'}
            viewBox={'0 0 24 24'}
            screenReaderLabel={'Search Icon'}
          >
            {searchIconPath}
          </Icon>
        </StyledSearchButton>
      )
    }
    if (searchTerm) {
      return (
        <StyledSearchButton
          isCaseManagement={isCaseManagement}
          $isReporting={isReporting}
          data-testid={'clearSearchText'}
          aria-label={'clear search box'}
          id={id}
          type={'button'}
          role='button'
          onClick={handleClearText}
          disabled={error}
        >
          <Icon
            scale={iconScale}
            fill={isCaseManagement ? 'none' : iconFill}
            cursorOnHover={'pointer'}
            viewBox={'0 0 24 24'}
            screenReaderLabel={'Close Search Icon'}
          >
            {closeIconPath}
          </Icon>
        </StyledSearchButton>
      )
    } else {
      return (
        <StyledSearchButton
          isCaseManagement={isCaseManagement}
          aria-label={'search'}
          type={'button'}
          role='button'
          disabled={error}
        >
          <Icon
            scale={iconScale}
            fill={isCaseManagement ? 'none' : iconFill}
            cursorOnHover={'default'}
            viewBox={'0 0 24 24'}
            screenReaderLabel={'Search Icon'}
          >
            {searchIconPath}
          </Icon>
        </StyledSearchButton>
      )
    }
  }
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    // it handles the zip code input in resources map
    if (onKeyPress) {
      if (onKeyPress(evt)) {
        if (evt.key === 'Enter' || evt.key === 'NumpadEnter') {
          setError && setError(false)
          !autoSearch && handleSearch(searchTerm)
        }
      }
    } else {
      if (evt.key === 'Enter' || evt.key === 'NumpadEnter') {
        setError && setError(false)
        !autoSearch && handleSearch(searchTerm)
      }
    }
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value
    if (onChange) {
      if (onChange(evt)) {
        setSearchTerm(value)
        autoSearch && handleSearch(value)
      }
    } else {
      setSearchTerm(value)
      autoSearch && handleSearch(value)
    }
  }

  return (
    <>
      <SrOnly>
        <label htmlFor='SearchInput'>Search</label>
      </SrOnly>
      <StyledInputField
        id='SearchInput'
        value={searchTerm}
        className={className}
        tabIndex={0}
        onDrag={(evt: React.DragEvent<HTMLInputElement>) =>
          selectText(evt.target as HTMLInputElement)
        }
        ref={inputElement}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        maxLength={maxCharLimit}
        inputMode='numeric'
        hasError={error}
        pattern={pattern}
        aria-label='The following text field filters the results as you type.'
      />
      {renderButton()}
    </>
  )
}

export default SearchInput
