import React, { useState } from 'react'
import { ApolloError } from '@apollo/client'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {
  CustomEmptyState,
  ResourcesWrapperContainer,
  StyledCaseCategoryWrapper,
  StyledCasesWrapper,
} from 'Components/ClientPortal/MyBenefits/style'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'

import DeleteUi from '../DeleteUi'
import Paginationresources from '../Pagination/Pagination'

import CaseCard from './mainCard'

type MyResourcesContentProps = {
  data: unknown
  loading: boolean
  error: ApolloError | undefined
  pageQuery?: number | undefined
  onPaginationChange?: unknown
}

const MainContent = ({
  data,
  loading,
  error,
  pageQuery,
  onPaginationChange,
}: MyResourcesContentProps) => {
  const [isDeleted, setIsDeleted] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedResource, setSelectedResource] = useState<unknown | null>(null)

  let getTotalItemsCount: number
  let updatedDataObject: unknown
  const pageSize = 12
  let pageCount = 1
  let itemsInSpecificPage: unknown

  function splitItemsForPaging() {
    getTotalItemsCount = updatedDataObject.length
    pageCount = Math.ceil(getTotalItemsCount / pageSize)

    if (!pageQuery || pageQuery > pageCount) {
      pageQuery = 1
    }
    itemsInSpecificPage = getItemsForPage(pageQuery)
  }

  function getItemsForPage(pageNumber: number) {
    const startIndex = (pageNumber - 1) * pageSize
    const endIndex = startIndex + pageSize
    return updatedDataObject.slice(startIndex, endIndex)
  }

  const handleDialogOpen = (resourceData: unknown) => {
    setSelectedResource(resourceData)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setSelectedResource(null)
  }

  if (loading) {
    return <LoadingIndicator show={true} />
  } else if (error) {
    return <div>ERROR_GENERIC_MESSAGE</div>
  } else if (data) {
    updatedDataObject = data
    const handlePageChange = (event, value) => {
      pageQuery = value
      onPaginationChange(value)
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      urlParams.set('page', value.toString())
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`
      const pageHistory = window.history

      pageHistory.replaceState(null, '', newUrl)
      setIsDeleted(false)
    }

    splitItemsForPaging()

    return (
      <>
        <ResourcesWrapperContainer>
          {updatedDataObject?.length > 0 ? (
            <>
              <StyledCaseCategoryWrapper
                className={isDeleted ? 'deleted-class' : ''}
              >
                <StyledCasesWrapper>
                  {itemsInSpecificPage.map((item: unknown, i: number) => (
                    <CaseCard
                      resourceData={item}
                      onDeleteToggle={(deleted) => setIsDeleted(deleted)}
                      onDeleteClick={() => handleDialogOpen(item)} // Pass resource data when clicked
                      key={item.id}
                    />
                  ))}
                </StyledCasesWrapper>
              </StyledCaseCategoryWrapper>
              {pageCount > 1 && (
                <Paginationresources
                  page={pageQuery}
                  onChange={handlePageChange}
                  count={pageCount}
                  onDeleteToggle={(deleted) => setIsDeleted(deleted)}
                />
              )}
            </>
          ) : (
            <StyledCaseCategoryWrapper>
              <CustomEmptyState>You don't have any saves yet</CustomEmptyState>
            </StyledCaseCategoryWrapper>
          )}
        </ResourcesWrapperContainer>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          className='myResourcesDialog'
        >
          <DialogContent>
            <DeleteUi
              resourceData={selectedResource}
              onDeleteToggle={handleDialogClose}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

export default MainContent
