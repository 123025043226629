import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { API_BASE } from 'Shared/constants'

import { getSessionAndLocalCredentials } from '../Shared/helpers'

import { customFetch } from './helpers'
import loadingIndicatorLink from './loadingIndicatorLink'

const server = `${API_BASE}/auth`

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getSessionAndLocalCredentials(),
    },
  }
})

const httpLink = new HttpLink({
  uri: server,
  fetch: customFetch,
})

const cache = new InMemoryCache()

const authClient = new ApolloClient({
  link: loadingIndicatorLink.concat(authLink.concat(httpLink)),
  cache: cache,
  resolvers: {},
  connectToDevTools: true,
})

export const reset = () => authClient.resetStore()

export default authClient
