import React from 'react'
import { StyledBorderBottom } from 'Components/LocalResources/LocationCard/style'
import ActionBar from 'Components/Shared/ActionBar/ActionBar'
import ActionButton from 'Components/Shared/ActionButton/ActionButton'
import SaveLocationButton from 'Components/Shared/LocationDetails/Header/SaveLocationButton'
import { isFeatureToggleOn } from 'Shared/helpers'
import {
  directionsIconPath,
  phoneIconPath,
  publicIconPath,
  shareIconPath,
} from 'Shared/Icons/LocationInfo/paths'

const ActionsToolbar = ({
  phoneNumber,
  lat,
  lng,
  url,
  activateFavorites,
  createFindHelpLocationInput,
}: {
  phoneNumber: string
  lat: number
  lng: number
  url: string
  activateFavorites: boolean
  createFindHelpLocationInput: {
    officeId: string
    providerId: string
    programId: string
  }
}) => {
  const actionsMap = [
    {
      icon: phoneIconPath,
      label: 'Call',
      callback: () =>
        phoneNumber ? window.open(`tel:+${phoneNumber}`, '_blank') : null,
      key: 'call',
      disabled: !phoneNumber,
      onKeyDown: true,
      ariaLabel: 'Call',
    },
    {
      icon: directionsIconPath,
      label: 'Directions',
      callback: () =>
        lat && lng
          ? window.open(
              `http://www.google.com/maps/place/${lat},${lng}`,
              '_blank'
            )
          : null,
      key: 'directions',
      disabled: !lat && lng,
      onKeyDown: true,
      ariaLabel: 'Get directions',
    },

    {
      icon: publicIconPath,
      label: 'Website',
      callback: () => (url ? window.open(url, '_blank') : null),
      key: 'website',
      disabled: !url,
      ariaLabel: 'Visit website',
      onKeyDown: true,
    },
    { icon: shareIconPath, label: 'Share', key: 'share', tabIndex: 0 },
  ]

  const actions = actionsMap.map((action) =>
    action.key !== 'share' ? <ActionButton {...action} /> : null
  )

  return (
    <StyledBorderBottom style={{ paddingBottom: '0' }}>
      <ActionBar actionType='ACTIONS' backgroundColor={'unset'}>
        {actions}
        {isFeatureToggleOn('show_favorites') && (
          <SaveLocationButton
            createFindHelpLocationInput={createFindHelpLocationInput}
            activateFavorites={activateFavorites}
          />
        )}
      </ActionBar>
    </StyledBorderBottom>
  )
}

export default ActionsToolbar
