import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import authClient from 'ApolloClient/authClient'
import CustomDialog from 'Components/Auth/CustomDialog/CustomDialog'
import { ME } from 'Components/Auth/Hooks/gql'
import useOtp from 'Components/Auth/Hooks/useOtp'
import { StyledSkipVerificationButton } from 'Components/Auth/style'
import useStep from 'Components/PreScreener/Hooks/useStep'
import { CASE_MANAGER_SKIP_CLIENT_OTP } from 'Components/PreScreener/SkipVerificationModal/gql'
import {
  ReverseCol,
  StyledDynamicCtaButton,
  StyledForm,
  StyledMainHeading,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
  StyledSubHeading,
} from 'Components/Screener/style'
import Icon from 'Components/Shared/Icon/Icon'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import { Field, reduxForm } from 'redux-form'
import { emailOrPhone as selectEmailOrPhone } from 'Selectors'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import backIconPath from 'Shared/Icons/backIconPath'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { useAppSelector } from 'Store/hooks'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { parseEmailOrPhone } from '../PreScreener/helpers'

const ClientAuth = ({ error, handleSubmit }) => {
  const focusRef = useRef(null)

  const { handleRequestOtp, loading } = useOtp()
  useEffect(() => {
    setFocusToMainHeading()
    setTimeout(() => {
      document.getElementById('otp')?.focus()
    }, 1000)
  }, [])

  const emailOrPhone = useSelector((state) => selectEmailOrPhone(state))
  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  const handleResendOtp = () => {
    handleRequestOtp(parseEmailOrPhone({ emailOrPhone }))
  }
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  const { data, loading: loadingMeQuery } = useQuery(ME)

  const verificationSkipped = useAppSelector(
    (state) => state.preScreener?.verificationSkipped
  )

  useEffect(() => {
    if (verificationSkipped) {
      handleUpdateStep(6)
    }
  }, [verificationSkipped])

  const { handleUpdateStep } = useStep()
  const isACaseManager = data && data?.me?.isACaseManager

  const [caseManagerSkipClientOtp] = useMutation(CASE_MANAGER_SKIP_CLIENT_OTP, {
    client: authClient,
  })

  const [openDialog, setOpenDialog] = useState(false)
  const handleModelOpen = (evt) => {
    evt.preventDefault()
    setOpenDialog(true)
  }
  const handleModelConfirm = (evt) => {
    evt.preventDefault()
    setOpenDialog(false)
    let userInfo = JSON.parse(sessionStorage.getItem('@@remember-client'))

    caseManagerSkipClientOtp({
      variables: {
        email: userInfo?.email || '',
        id: userInfo?.id || '',
        skip: true,
      },
    })
      .then(() => {
        handleUpdateStep(6)
      })
      .catch(() => {
        console.log('Something went wrong')
      })
  }
  const StyledSkipVerificationButtonComp = useMemo(() => {
    return () => {
      return (
        <StyledSkipVerificationButton
          color={'#0D5E46'}
          borderColor={'#0D5E46'}
          marginTop={'1rem'}
          width={'100%'}
          onClick={(e) => handleModelOpen(e)}
        >
          Skip Verification
          <Icon
            position={'absolute'}
            right={'1rem'}
            top={'1.4rem'}
            fill={'#0D5E46'}
            margin={'0'}
            scale={'1.05rem'}
            style={{ transform: 'rotate(180deg)' }}
            viewBox={'0 0 24 24'}
            cursorOnHover={'pointer'}
          >
            {backIconPath}
          </Icon>
        </StyledSkipVerificationButton>
      )
    }
  }, [])

  if (loadingMeQuery) {
    return (
      <div style={{ height: '50vh' }}>
        <LoadingIndicator show={true} />
      </div>
    )
  }
  return (
    <Container>
      <CustomDialog
        open={openDialog}
        title='Are you sure you want to skip verification?'
        content='Please note that by selecting "Yes," the client will become unverified. The client will not be able to access their profile without the assistance of a case manager.'
        onConfirm={(e) => {
          handleModelConfirm(e)
          // handleSubmit()
          // setOpenDialog(false)
        }}
        onCancel={() => setOpenDialog(false)}
      />
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm padding={'2rem 0 0'} onSubmit={handleSubmit} noValidate>
            <StyledMainHeading
              id='mainContent'
              tabIndex='-1'
              ref={focusRef}
              data-testid='main-heading'
            >
              Verify Your Account
            </StyledMainHeading>
            <StyledParagraph>
              Please take a moment to verify your contact information. This
              helps us to confirm your identity and secure your account.
            </StyledParagraph>
            <StyledSubHeading>
              Please enter the verification code sent to:
            </StyledSubHeading>
            <StyledSubHeading>{emailOrPhone}</StyledSubHeading>
            <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
              <MuiThemeProvider theme={ssTheme}>
                <StyledHouseholdMembersList>
                  <Row>
                    <Col lg='12'>
                      <StyledMuiTextFieldWrapper
                        width='100%'
                        marginRight='auto'
                        marginLeft='auto'
                        mbottom='0'
                      >
                        <Field
                          name='otp'
                          type='text'
                          component={TextField}
                          disabled={verificationSkipped}
                          label='Verification Code'
                        />
                        <FormSubmissionError error={error} />
                      </StyledMuiTextFieldWrapper>
                    </Col>
                  </Row>
                </StyledHouseholdMembersList>
              </MuiThemeProvider>
            </StyledHouseholdMembersListItem>
            {isMobile ? (
              <StyledParentButtonsActionsInMobile>
                <ReverseCol>
                  <StyledParentPreScreenerButton width='100%'>
                    <StyledDynamicCtaButton
                      width='100%'
                      $hasExtraButton
                      type='button'
                      onClick={handleResendOtp}
                      disabled={loading}
                      margin='1rem 0'
                    >
                      Resend Code
                    </StyledDynamicCtaButton>
                    {isACaseManager && (
                      <StyledSkipVerificationButtonComp width={'100%'} />
                    )}
                  </StyledParentPreScreenerButton>
                  <StyledParentPreScreenerButton width='100%'>
                    <ColDynamicCtaButton smallerMargin='0' />
                  </StyledParentPreScreenerButton>
                </ReverseCol>
              </StyledParentButtonsActionsInMobile>
            ) : (
              <StyledHouseholdMembersList textalign='center'>
                <Row>
                  <Col lg='12'>
                    <ColDynamicCtaButton />
                    <StyledDynamicCtaButton
                      width='100%'
                      $hasExtraButton
                      type='button'
                      onClick={handleResendOtp}
                      disabled={loading}
                      margin='1rem 0'
                    >
                      Resend Code
                    </StyledDynamicCtaButton>
                    {isACaseManager && (
                      <StyledSkipVerificationButtonComp
                        skipVerficationCase={verificationSkipped}
                      />
                    )}
                  </Col>
                </Row>
              </StyledHouseholdMembersList>
            )}
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}
export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validators.otp,
  onSubmitFail: setFocusOnFirstError,
})(ClientAuth)
