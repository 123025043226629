import React, { useCallback, useEffect, useState } from 'react'
import { TabletView } from 'react-device-detect'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api'
import { updateQuery } from 'Actions/filters'
import { updateCoordinates, updateResourcesZipCode } from 'Actions/geolocation'
import { StyledTextDiv } from 'Components/LandingPage/Components/CookiesConsent/style'
import type {
  Coordinates,
  FindHelpResource,
} from 'Components/LocalResources/FindHelpResource'
import DesktopSecondaryActionButton from 'Components/Shared/DesktopSecondaryActionButton/DesktopSecondaryActionButton'
import Icon from 'Components/Shared/Icon/Icon'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { StyledLoadingAnimationWrapper } from 'Components/Shared/LoadingIndicator/style'
import { StyledButton } from 'Pages/LocationRequest/style'
import SearchThisAreaButton from 'Pages/Locations/Components/SearchThisAreaButton'
import { updateShowLeftColumn } from 'Reducers/uiSlice'
import {
  DESKTOP_MEDIA_QUERY,
  GOOGLE_API_KEY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { getCoordinatesFromZipCode } from 'Shared/helpers'
import theme from 'Shared/Theme/ssTheme'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import CategoryFilterMegaMenu from '../Filtering/CategoryFilter/CategoryFilterMegaMenu'

import mapStyle from './mapStyle'
import MarkerWithInfoWindow from './MarkerWithInfoWindow'
import { MapWrapper, StyedNotificationBanner, StyledContainer } from './style'

const DEFAULT_ZOOM = 11

const mapOptions = {
  styles: mapStyle,
  disableDefaultUI: true,
  zoomControl: true,
}

type LocationsMapProps = {
  showSearchThisAreaButton: boolean
  setSearchThisArea: (searchThisArea: boolean) => void
}

const LocationsMap = ({
  showSearchThisAreaButton,
  setSearchThisArea,
}: LocationsMapProps) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' })

  const isMobileOrTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  const { locationId } = useParams()

  const [map, setMap] = useState<google.maps.Map>()
  const [isDragging, setIsDragging] = useState(false)
  const [activeMarker, setActiveMarker] = useState<number | null>(null)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const loading = useAppSelector((state) => state.ui.loading)
  const loadingError = useAppSelector((state) => state.ui.loadingError)

  const { showZeroSearchResultsMessage: zeroSearchResults } = useAppSelector(
    (state) => state.ui
  )

  const locationsObj: Record<string, FindHelpResource> = useAppSelector(
    (state) => state.locations.searchResults
  )
  const { coordinates: userLocation, resourcesZipCode } = useAppSelector(
    (state) => state.geolocation
  )
  const query = useAppSelector((state) => state.filters.query) || ''

  const locationsWithCoordinates = useCallback(() => {
    return Object.values(locationsObj).filter((location) => {
      const { coordinates } = location
      return coordinates?.lat && coordinates?.lng
    })
  }, [locationsObj])

  const updateBounds = useCallback(
    (map: google.maps.Map) => {
      if (!isDragging) {
        const { google } = window
        const bounds = new google.maps.LatLngBounds()
        const locations = locationsWithCoordinates()
        // if there are no search results and we have a user location
        if (zeroSearchResults && userLocation) {
          const { lat, lng } = userLocation
          if (lat && lng) {
            // set the bounds to the user's location
            // also set the zoom level
            bounds.extend(userLocation)
            map.fitBounds(bounds)
            map.setZoom(DEFAULT_ZOOM)
          }
        } else if (!zeroSearchResults && userLocation && !locations.length) {
          const { lat, lng } = userLocation
          if (lat && lng) {
            // set the bounds to the user's location
            // also set the zoom level
            bounds.extend(userLocation)
            map.fitBounds(bounds)
            map.setZoom(18)
          }
        } else {
          locations.forEach((location) => {
            const { coordinates } = location
            bounds.extend(coordinates)
          })
          map.fitBounds(bounds)
          map.setZoom(DEFAULT_ZOOM)
        }
      }
    },
    [locationsWithCoordinates, userLocation, zeroSearchResults, isDragging]
  )

  const shiftMapCenter = (map: google.maps.Map) => {
    if (locationId) {
      // Find the location with the matching locationId
      const selectedLocation = locationsWithCoordinates().find(
        (loc) => loc.id === locationId
      )

      if (selectedLocation && selectedLocation.coordinates) {
        const { lat, lng } = selectedLocation.coordinates
        // Create a LatLng object for the location
        const locationLatLng = new google.maps.LatLng(lat, lng)
        // Get the map's projection
        const projection = map.getProjection()
        if (projection) {
          // Convert location to point
          const point = projection.fromLatLngToPoint(locationLatLng)
          if (point) {
            // Shift the point 400 pixels to the left (which will push the map right)
            point.x -= 490 / Math.pow(2, map.getZoom() || 0)

            // Convert back to LatLng
            const newCenter = projection.fromPointToLatLng(point)
            if (newCenter) {
              // Set the new center
              map.setCenter(newCenter)
            }
          }
        }
      }
    }
  }

  const handleOnLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map)
      map.setOptions({ styles: mapStyle, disableDefaultUI: true })
      updateBounds(map)
    },
    [updateBounds]
  )

  const handleDesktopMarkerClick = (
    _event: google.maps.MapMouseEvent,
    _coordinates: Coordinates,
    id: number
  ) => {
    setActiveMarker(+id)
    dispatch(updateShowLeftColumn(true))
    navigate(`${id}`, { replace: true })
  }

  const handleMobileAndTabletMarkerClick = (
    _event: google.maps.MapMouseEvent,
    coordinates: Coordinates,
    id: number
  ) => {
    map?.panTo(coordinates)
    setActiveMarker(+id)
    navigate(`${id}`, { replace: true })
  }

  useEffect(() => {
    if (map && resourcesZipCode) {
      getCoordinatesFromZipCode(resourcesZipCode).then((coordinates) => {
        const bounds = new google.maps.LatLngBounds()
        bounds.extend(coordinates)
        map.fitBounds(bounds)
        map.setCenter(coordinates)
        map.setZoom(DEFAULT_ZOOM)
      })
    }
  }, [map, resourcesZipCode])

  useEffect(() => {
    if (map) {
      updateBounds(map)
    }
  }, [updateBounds, map, locationId])

  useEffect(() => {
    if (locationId && map) {
      setActiveMarker(+locationId)
      if (isDesktop) {
        shiftMapCenter(map as google.maps.Map)
      }
    } else {
      setActiveMarker(null)
    }
  }, [locationId])

  const renderMarkers = (device?: string) => {
    const locations = locationsWithCoordinates()

    const locationMarkers = locations.map((location) => {
      const { coordinates, id, singleStop } = location
      const currentSingleStopLocationIcon =
        id === locationId
          ? 'preferredPartnerSelected.svg'
          : 'starPreferredPartner.svg'
      const currentLocationIcon =
        Number(id) === Number(locationId) ? 'Active.svg' : 'Normal.svg'
      const icon = singleStop
        ? `${window.location.origin}/map/${currentSingleStopLocationIcon}`
        : `${window.location.origin}/map/${currentLocationIcon}`

      const clickHandler =
        device === 'desktop'
          ? handleDesktopMarkerClick
          : handleMobileAndTabletMarkerClick
      return (
        <MarkerWithInfoWindow
          key={id}
          device={device}
          coordinates={coordinates}
          icon={icon}
          onClick={(event) => clickHandler(event, coordinates, +id)}
          location={location}
          activeId={activeMarker}
        />
      )
    })

    return locationMarkers
  }

  const clearZipcodeFromSearch = () => {
    const zipCodeRegex = /(\d{5})/g
    const match = query.match(zipCodeRegex)
    if (match) {
      const zipcodeLessQuery = query.replace(match[0], '')
      dispatch(updateResourcesZipCode(''))
      dispatch(updateQuery(zipcodeLessQuery))
    }
  }

  const renderMap = (device?: string) => {
    return (
      <LoadScriptNext
        id='script-loader'
        googleMapsApiKey={`${GOOGLE_API_KEY}&loading=async` || ''}
      >
        <MapWrapper
          className='map-wrapper'
          style={{
            height: isMobileOrTablet
              ? TabletView
                ? 'calc(100vh - 100px)'
                : 'calc(100vh)'
              : `calc(100vh - ${theme.layout.headerHeight})`,
          }}
          role='region'
          aria-label='map'
        >
          <GoogleMap
            id='locations-map'
            options={mapOptions}
            mapContainerStyle={{
              minHeight: '100%',
              height: isMobileOrTablet
                ? TabletView
                  ? 'calc(100vh - 100px)'
                  : 'calc(100vh)'
                : `calc(100vh - ${theme.layout.headerHeight})`,
            }}
            onDragStart={() => setIsDragging(true)}
            onDragEnd={() => {
              const c = map?.getCenter()
              const lat = c?.lat()
              const lng = c?.lng()
              const coordinates = { lat, lng }
              clearZipcodeFromSearch()
              dispatch(updateCoordinates(coordinates as Coordinates))
            }}
            onLoad={handleOnLoad}
          >
            {showSearchThisAreaButton && (
              <SearchThisAreaButton
                handleClick={() => {
                  setSearchThisArea(true)
                }}
              />
            )}
            <>
              <Outlet />
            </>
            {renderMarkers(device)}
          </GoogleMap>
          <CurrentLocate map={map as google.maps.Map} />
        </MapWrapper>
      </LoadScriptNext>
    )
  }

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        {loadingError && <LoadingErrorBanner />}
        {loading && <LoadingIndicator show={true} />}
        <StyledContainer>{renderMap()}</StyledContainer>
      </MediaQuery>

      <MediaQuery query={TABLET_MEDIA_QUERY}>
        {loadingError && <LoadingErrorBanner />}
        {loading && <LoadingIndicator show={true} />}
        <StyledContainer>
          {renderMap('tablet')}
          <DesktopSecondaryActionButton />
        </StyledContainer>
      </MediaQuery>

      <MediaQuery query={DESKTOP_MEDIA_QUERY}>
        {loadingError && <LoadingErrorBanner />}
        {loading && <LoadingIndicator show={true} />}
        <StyledContainer>
          {renderMap('desktop')}
          <DesktopSecondaryActionButton />
          {resourcesZipCode ? <CategoryFilterMegaMenu /> : ''}
        </StyledContainer>
      </MediaQuery>
    </>
  )
}

const CurrentLocate = ({ map }: { map: google.maps.Map }) => {
  const panToCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        map.setZoom(18)
        map.setCenter(pos)
      })
    }
  }

  return (
    <StyledButton mylocation type='button' onClick={panToCurrentLocation}>
      <Icon
        viewBox={'0 0 24 24'}
        scale={'1.7rem'}
        padding={'0'}
        margin={'0'}
        cursorOnHover={'pointer'}
        screenReaderLabel='Find My location'
      >
        <path className='a' fill='none' d='M0,0H24V24H0Z' />
        <path
          className='b'
          fill='#fff'
          d='M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm8.94,3A8.994,8.994,0,0,0,13,3.06V1H11V3.06A8.994,8.994,0,0,0,3.06,11H1v2H3.06A8.994,8.994,0,0,0,11,20.94V23h2V20.94A8.994,8.994,0,0,0,20.94,13H23V11H20.94ZM12,19a7,7,0,1,1,7-7A6.995,6.995,0,0,1,12,19Z'
        />
      </Icon>
      <SrOnly>Find My location</SrOnly>
    </StyledButton>
  )
}

const LoadingErrorBanner = () => (
  <StyledLoadingAnimationWrapper>
    <StyedNotificationBanner>
      <StyledTextDiv>
        Resources are currently unavailable. Please try again later.
      </StyledTextDiv>
    </StyedNotificationBanner>
  </StyledLoadingAnimationWrapper>
)

export default LocationsMap
