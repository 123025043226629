import React from 'react'

import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
  CustomStyledButton,
  CustomStyledDialog,
} from './style'

const CustomDialog = ({ open, title, content, onConfirm, onCancel }) => {
  return (
    <CustomStyledDialog
      open={open}
      onClose={onCancel}
      className='customStyledDialog'
    >
      <CustomDialogTitle>{title}</CustomDialogTitle>
      <CustomDialogContent>{content}</CustomDialogContent>
      <CustomDialogActions>
        <CustomStyledButton
          onClick={onCancel}
          color='primary'
          className='cancel-btn'
        >
          Cancel
        </CustomStyledButton>
        <CustomStyledButton
          onClick={onConfirm}
          color='secondary'
          className='skip-btn'
        >
          Yes, Skip verification
        </CustomStyledButton>
      </CustomDialogActions>
    </CustomStyledDialog>
  )
}

export default CustomDialog
