import { gql } from '__generated__/gql'

export const CREATE_REFERRAL = gql(`
  mutation createReferral($clientId: ID!, $inputs: [CreateReferralInput!]!) {
    createReferral(clientId: $clientId, inputs: $inputs) {
      referrals {
        id
        origin
        recipientType
        createdAt
      }
    }
 }`)
