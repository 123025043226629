import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'

import { StyledWrapper } from './style'

const NextStepsDefaultCopy = () => {
  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  return (
    <StyledWrapper
      style={{ paddingBottom: isTabletAndMobile ? '16px' : '2rem' }}
    >
      <p
        style={{
          color: '#545456',
          fontWeight: '400',
          fontSize: isTabletAndMobile ? '14px' : '18px',
          marginBottom: '20px',
        }}
      >
        Now that you've completed the screening process, it's time to get ready
        to apply for benefits.
      </p>
      <p
        style={{
          color: '#545456',
          fontWeight: '400',
          fontSize: isTabletAndMobile ? '14px' : '18px',
          marginBottom: '5px',
        }}
      >
        Benefit guides for your eligible programs can be found below. These
        guides have important information about the documents you'll need as
        well as how to navigate the application process.
      </p>
    </StyledWrapper>
  )
}

export default NextStepsDefaultCopy
