import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledCardComponent = styled.div`
  margin: 15px 0px 0;
  background-color: #eef9f6;
  border-radius: 12px;
  padding: 12px 15px;
  color: #151515;
`
export const StyledCardComponentHeader = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 28.8px;
  font-weight: 600;
  font-family: 'Noto Sans HK', sans-serif;
`
export const StyledCardComponentInfo = styled.div`
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 22.4px;
  font-weight: 450;
  font-family: 'Noto Sans HK', sans-serif;
`

export const CustomNavLink = styled(NavLink)`
  color: #08674a;
`
export const StyledCardComponentInfoLocationWrapper = styled.div`
  margin-bottom: 8px;
`

export const StyledInfoTime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 14px;
  color: rgb(13, 135, 98);
  @media only screen and (max-width: 481px) {
    gap: 10px;
  }
`
export const StyledInfoTimeStatus = styled.div`
  padding: 4px 13px;
  background-color: white;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
  color: rgb(13, 135, 98);
  &.StyledInfoTimeStatus--opened {
    color: #08674a;
    background-color: #eef9f6 !important;
  }
  &.StyledInfoTimeStatus--closed {
    color: #e31010;
    background-color: #fde6e5 !important;
  }
  @media only screen and (max-width: 481px) {
  }
`
export const StyledInfoTimeInfo = styled.div`
  align-items: center;
  color: #075f48;
  display: inline-flex;
  .StyledInfoTimeInfo__capitalized {
    text-transform: capitalize;
  }
`
