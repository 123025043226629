import React, { useEffect, useRef, useState } from 'react'
import MediaQuery from 'react-responsive'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useScrollOnDrag from 'react-scroll-ondrag'
import { Scrollbar } from 'react-scrollbars-custom'
import { useQuery } from '@apollo/client'
import { Dialog, DialogContent, SwipeableDrawer } from '@material-ui/core'
import { fetchLocationDetails } from 'Actions/locations'
import { FindHelpResource } from 'Components/LocalResources/FindHelpResource'
import { StyledChip } from 'Components/Shared/CommonElements'
import Icon from 'Components/Shared/Icon/Icon'
import Category from 'Components/Shared/LocationDetails/Category'
import ExpandedLocationHeader from 'Components/Shared/LocationDetails/Header'
import { GET_FAVORITE_LOCATIONS } from 'Components/Shared/LocationDetails/Header/gql'
import Title from 'Components/Shared/LocationDetails/Header/Title'
import { updateShowLocationList } from 'Reducers/uiSlice'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { preferredStar } from 'Shared/Icons/LocationInfo/paths'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import MainContent from './MainContent/MainContent'
import { StyledBox, StyledMobileContainer, StyledPuller } from './style'

type LocationDetailProps = {
  clientId?: string
  setReferralData?: (referralData: Record<string, string>) => void
  parentCategoryIdFromContext?: number
  activateFavorites: boolean
  createFindHelpLocationInput: {
    officeId: string
    providerId: string
    programId: string
  }
}

const LocationDetail = ({
  clientId,
  setReferralData,
  parentCategoryIdFromContext,
}: LocationDetailProps) => {
  const id = useParams().locationId
  const dispatch = useAppDispatch()

  const boundUpdateShowLocationList = () =>
    dispatch(updateShowLocationList(false))

  const boundFetchLocationDetails = () => dispatch(fetchLocationDetails(id))

  const {
    locationDetail,
    searchResults,
  }: {
    locationDetail: FindHelpResource
    searchResults: Record<string, FindHelpResource>
  } = useAppSelector((state) => state.locations)

  const location = id ? searchResults[id] : locationDetail

  useEffect(() => {
    boundFetchLocationDetails()
    boundUpdateShowLocationList()
  }, [])

  const { state: createFindHelpLocationInput } = useLocation()

  // why return null if !location.quickFilters?
  if (!location) return null

  return (
    <>
      {/* Desktop */}
      <DesktopView
        setReferralData={setReferralData}
        clientId={clientId}
        parentCategoryIdFromContext={parentCategoryIdFromContext}
        createFindHelpLocationInput={createFindHelpLocationInput}
      />
      {/* Tablet */}
      <TabletView
        setReferralData={setReferralData}
        clientId={clientId}
        parentCategoryIdFromContext={parentCategoryIdFromContext}
        createFindHelpLocationInput={createFindHelpLocationInput}
      />
      {/* Mobile */}

      <MobileView
        setReferralData={setReferralData}
        clientId={clientId}
        parentCategoryIdFromContext={parentCategoryIdFromContext}
        createFindHelpLocationInput={createFindHelpLocationInput}
      />
    </>
  )
}

export default LocationDetail

const Location = ({
  clientId,
  setReferralData,
  parentCategoryIdFromContext,
  createFindHelpLocationInput,
}: LocationDetailProps) => {
  const {
    locationDetail,
    searchResults,
  }: {
    locationDetail: FindHelpResource
    searchResults: Record<string, FindHelpResource>
  } = useAppSelector((state) => state.locations)
  const id = useParams().locationId
  const ref = useRef()
  const { events } = useScrollOnDrag(ref)
  const { data } = useQuery(GET_FAVORITE_LOCATIONS)
  const [activateFavorites, setActivateFavorites] = useState(false)
  useEffect(() => {
    if (data && !data.me.isACaseManager) {
      setActivateFavorites(true)
    }
  }, [data])

  const location = id ? searchResults[id] : locationDetail
  const { name, provider_name, program_name } = location
  const renderedCategories = location?.services?.map((service) => {
    return (
      <div key={service} id={service}>
        <MediaQuery query={DESKTOP_MEDIA_QUERY}>
          {(matches) => {
            const fill = matches ? 'rgb(255,255,255)' : 'rgb(155, 195, 103)'
            return (
              <Category
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                maxHeight={'100px'}
                key={service}
                name={service}
                backgroundColor={'rgb(59,69,47)'}
                fill={fill}
              />
            )
          }}
        </MediaQuery>
      </div>
    )
  })

  const renderSingleStop = () => {
    const { singleStop } = location

    if (!singleStop) return null

    return (
      <StyledChip actionType='HEADER'>
        <Icon
          fill={'auto'}
          scale={'1.5rem'}
          margin={'0 1rem 0 0'}
          viewBox={'0 0 22.527 22.527'}
          screenReaderLabel='Preferred Partner Icon'
        >
          {preferredStar}
        </Icon>
        Preferred Partner
      </StyledChip>
    )
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: '0',
      }}
      ref={ref}
      {...events}
    >
      <Scrollbar
        style={{
          height: '100%',
          width: '100%',
          overflowX: 'hidden',
        }}
        permanentTrackX={false}
        trackYProps={{
          style: {
            background: 'transparent',
            width: '8px',
          },
        }}
        thumbYProps={{
          style: {
            background: 'rgba(0, 0, 0, 0.35)',
          },
        }}
        trackXProps={{
          style: {
            display: 'none',
          },
        }}
        removeTrackXWhenNotUsed
      >
        <Title
          preferredPartner={renderSingleStop()}
          name={name}
          program_name={program_name}
          provider_name={provider_name}
        />

        <div
          style={{
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <ExpandedLocationHeader
            location={location}
            badges={renderedCategories}
            preferredPartner={renderSingleStop()}
            clientId={clientId}
            setReferralData={setReferralData}
            parentCategoryIdFromContext={parentCategoryIdFromContext}
            activateFavorites={activateFavorites}
            createFindHelpLocationInput={createFindHelpLocationInput}
          />
          <MainContent location={location} padding={'0 0 5.6rem'} />
        </div>
      </Scrollbar>
    </div>
  )
}

const DesktopView = ({
  clientId,
  setReferralData,
  parentCategoryIdFromContext,
  createFindHelpLocationInput,
}: LocationDetailProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <MediaQuery query={DESKTOP_MEDIA_QUERY}>
      <Dialog
        hideBackdrop
        open={true}
        transitionDuration={{
          appear: 2000,
          enter: 1500,
          exit: 1000,
        }}
        onClose={() => {
          navigate(location.pathname.split('/list')[0] + '/list')
        }}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '100%',
            padding: '0',
            margin: '0',
            height: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            boxShadow: 'none',
            borderRadius: '1rem',
          },
        }}
        scroll={'paper'}
        style={{
          maxHeight: 'fit-content',
          height: 'calc(100vh - 220px)',
          left: location.pathname.includes('case-management')
            ? '35rem'
            : '25rem',
          marginLeft: '4px',
          width: '30rem',
          overflowX: 'hidden',
          position: 'absolute',
          top: '170px',
          zIndex: '1300',
          boxShadow: 'none',
        }}
      >
        <DialogContent
          style={{
            padding: 0,
            overflowX: 'hidden',
          }}
        >
          <Location
            setReferralData={setReferralData}
            clientId={clientId}
            parentCategoryIdFromContext={parentCategoryIdFromContext}
            createFindHelpLocationInput={createFindHelpLocationInput}
          />
        </DialogContent>
      </Dialog>
    </MediaQuery>
  )
}
const TabletView = ({
  clientId,
  setReferralData,
  parentCategoryIdFromContext,
  createFindHelpLocationInput,
}: LocationDetailProps) => {
  return (
    <MediaQuery query={TABLET_MEDIA_QUERY}>
      <div
        style={{
          background: 'white',
          height: '100%',
          left: '0',
          marginLeft: '4px',
          width: '420px',
          overflowX: 'hidden',
          position: 'absolute',
          top: '0',
          zIndex: '100',
          boxShadow: 'none',
        }}
      >
        <Location
          setReferralData={setReferralData}
          clientId={clientId}
          parentCategoryIdFromContext={parentCategoryIdFromContext}
          createFindHelpLocationInput={createFindHelpLocationInput}
        />
      </div>
    </MediaQuery>
  )
}
const drawerBleeding = 56

const MobileView = ({
  clientId,
  setReferralData,
  parentCategoryIdFromContext,
  createFindHelpLocationInput,
}: LocationDetailProps) => {
  const [open, setOpen] = React.useState(true)

  const locationId = useParams()
  useEffect(() => {
    if (locationId) {
      setOpen(true)
    }
    return () => {
      setOpen(false)
    }
  }, [locationId])

  return (
    <MediaQuery query={MOBILE_MEDIA_QUERY}>
      <SwipeableDrawer
        variant='temporary'
        hideBackdrop
        disableAutoFocus
        BackdropProps={{
          style: {
            display: 'none',
          },
        }}
        draggable
        PaperProps={{
          style: {
            overflowX: 'hidden',
            height: '60%',
            overflowY: 'scroll',
            borderRadius: '2.5rem 2.5rem 0 0',
            paddingTop: '2.5rem',
          },
        }}
        disableBackdropTransition
        anchor={'bottom'}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        swipeAreaWidth={drawerBleeding}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <StyledBox>
          <StyledPuller />
        </StyledBox>
        <StyledMobileContainer>
          <Location
            setReferralData={setReferralData}
            clientId={clientId}
            parentCategoryIdFromContext={parentCategoryIdFromContext}
            createFindHelpLocationInput={createFindHelpLocationInput}
          />
        </StyledMobileContainer>
      </SwipeableDrawer>
    </MediaQuery>
  )
}
