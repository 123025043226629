import styled from 'styled-components'

export const StyledBox = styled.div`
  position: absolute;
  top: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  visibility: visible;
  right: 0;
  left: 0;
  z-index: 9999999;
`
export const StyledMobileContainer = styled.div`
  height: 100%;

  .mobile-location-details {
    z-index: 1450000;
    position: relative;
    height: 100%;
    overflow-y: scroll;
  }
`

export const StyledPuller = styled.div`
  width: 30px;
  height: 6px;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: calc(50% - 15px);
  background-color: gray;
  z-index: 9999999;
`
