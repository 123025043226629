import { gql } from '__generated__/gql'

export const TOGGLE_FAVORITE_LOCATION = gql(`
    mutation toggleFavoriteLocation(
        $input:  ToggleFavoriteLocationInput!
    ) {
    toggleFavoriteLocation(
        input: $input
    ) {
        findHelpLocation {
            id
        }
    }
}`)
